@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

#sidebar-menu {
    ul {
        li {
            a {
                i {
                    min-width: 2rem;
                }
            }
        }
    }
}

.noti-icon .badge {
    left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
    float: right;
}

.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}

.dz-message {
    text-align: center;
    padding: 30px;
}

// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
    background-color: $card-bg !important;
    margin: 0 20px 0 0 !important;
}

.task-box {
    border: 1px solid $border-color;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.ReactModal__Overlay {
    z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
    margin-right: 15px;
}

.external-event {
    &:hover {
        cursor: pointer;
    }
}

a:hover {
    cursor: pointer;
}

.rating-container {
    background-color: transparent !important;
}

.input-group-append {
    z-index: 0;
}

.input-color {
    color: $input-color !important;
}

.sketch-picker {
    position: absolute;
    z-index: 1;
}

.rangeslider__fill {
    background-color: $primary !important;
}

.footer {
    position: unset;
    bottom: 0px;
    left: 0px;
    background-color: #fff;
    color: #022fbf;
    box-shadow: 0.5rem -0.1rem 1rem rgba(57, 68, 82, 0.2);
}

button#page-header-notifications-dropdown {
    margin-top: 6px;
    margin-right: 15px;
}

.btn.btn-sm i {
    position: relative;
    top: 2px;
}

.btn {
    font-size: 0.8rem;
}

.ant-message {
    z-index: 1090;
}

.block {
    display: block;
}

@media (min-width: 1200px) {

    body[data-layout="horizontal"] .container-fluid,
    body[data-layout="horizontal"] .container-sm,
    body[data-layout="horizontal"] .container-md,
    body[data-layout="horizontal"] .container-lg,
    body[data-layout="horizontal"] .container-xl,
    body[data-layout="horizontal"] .navbar-header {
        max-width: 93%;
    }
}

body {
    background-color: #f9f9fc;
    font-family: Poppins, "Open Sans", sans-serif;
}

#sidebar-menu ul li a i {
    color: #022fbf;
}

#sidebar-menu ul li a,
#sidebar-menu ul li ul.sub-menu li a,
.topbar-name {
    color: #0b0146;
    font-size: 14px;
    font-weight: 400;
}

.btn-topbar {
    padding: 7px 20px;
    font-size: 14px;
}

.logo {
    font-weight: 500;
    font-size: 17px;
}

.navbar-brand-box {
    width: 220px;
}

.ant-popover {
    z-index: 1090;
}

.ant-select.ant-select-single {
    font-size: 14px;
    color: #555;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #d9d9d9;
    height: 39px;
    padding: 5px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 37px;
}

@media (max-width: 540px){
    .hide-sm {
        display: none;
    }
}

.card-header {
    background-color: #fff;
    padding: 18px 15px 15px;
    border-bottom: 1px solid #ebedf2;
}

.page-title-box h4 {
    text-transform: unset;
    font-weight: 400;
}
.page-content {
    min-height: 92vh;
}

.full-width {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

@media (min-width: 768px) {
    .lesson-video-container {
        position: sticky;
        position: -webkit-sticky;
        top: 100px;
    }
}

.main-content {
    overflow: unset;
}
label {
    font-weight: 400;
    margin-bottom: 5px;
}

.modal-open {
    overflow: scroll !important;
}